import { MessageTypeCss, MessageTypeLabel, ModuleTypeCss, ModuleTypeLabel, TypeResponse } from '@app/shared/enum';
import { MessageQueueService } from '@app/marketing/services';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from '@app/shared/services';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.css']
})
export class NotificationViewComponent implements OnInit {
  @Input("userId") userId: any;
  @Input("moduleId") moduleId: any;
  @Input("notificationId") notificationId: any;
  public validNotification: boolean;
  public notice: any;

  constructor(
    private router: Router,
    private loading: MessageService,
    private messageQueueService: MessageQueueService) {
  }

  ngOnInit(): void {
    this.loadNotificationById(this.notificationId);
  }

  public done() {
  }

  public cancel() {
  }

  loadNotificationById(id: any) {
    this.loading.showLoading();
    this.messageQueueService.getById(id).pipe(first()).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validNotification = true;
        this.designItem(response.data)
      } else {
        //this.returnMessage = response.message;
        this.validNotification = true;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      if (error.error.error === 'invalid_token') {
        this.router.navigate(['/auth/login']);
      }
    });
  }

  designItem(item: any) {
    if (item.messageType >= 0) {
      let messageTypeStr = MessageTypeLabel.get(item.messageType);
      let messageTypeCss = MessageTypeCss.get(item.messageType);
      item.designMessageType = "<span class='badge w-100 pull-right "
        + messageTypeCss +" badge-pill'>"
        + messageTypeStr +"</span>";
    }

    if (item.module >= 0) {
      let moduleTypeStr = ModuleTypeLabel.get(item.module);
      let moduleTypeCss = ModuleTypeCss.get(item.module);
      item.designModule = "<span class='badge w-30 pull-right "
        + moduleTypeCss +" badge-pill'>"
        + moduleTypeStr +"</span>";
    }
    this.notice = item;
  }
}
