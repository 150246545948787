<div class="modal-header">
  <h3 class="modal-title">
    Avaliação do teste do <span class="fw-300"><i>Aluno</i></span>
  </h3>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="dismissModal()"
  >
    <span aria-hidden="true"><i class="fal fa-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <div id="panel-2" class="panel">
    <div class="panel-hdr">
      <h2>Revisão de teste<span class="fw-300"></span></h2>
    </div>
    <div class="panel-container show">
      <div class="panel-content p-0">
        <form
          [formGroup]="writingTestAppliedReviewForm"
          novalidate
          class="needs-validation"
          (ngSubmit)="onSubmit()"
        >
          <div class="panel-content">
            <div class="form-row">
              <input
                type="hidden"
                id="writingTestApplyId"
                formControlName="writingTestApplyId"
              />
              <input
                type="hidden"
                id="writingTestStatus"
                formControlName="writingTestStatus"
              />
              <div class="col-4 mb-3">
                <div class="form-group" *ngIf="!previewMode">
                  <label class="form-label" for="finishDate"
                    >Data de realização do teste
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    id="finishDate"
                    formControlName="finishDate"
                    class="form-control"
                    placeholder="Data de realização do teste do aluno"
                    [ngClass]="{
                      'is-invalid': !validEntity && f.finishDate.errors
                    }"
                    required
                  />
                  <div
                    *ngIf="!validEntity && f.finishDate.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.finishDate.errors.required">
                      Por favor, informe a data de realização do teste do aluno.
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="previewMode">
                  <label class="form-label" for="finishDate"
                    >Data de realização do teste</label
                  >
                  <label class="form-control font-weight-bold disabled">{{
                    f.finishDate.value | date : "dd/MM/yyyy"
                  }}</label>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="form-group" *ngIf="!previewMode">
                  <label class="form-label" for="contentReviewHtml"
                    >Teste do aluno <span class="text-danger">*</span>
                  </label>
                  <div
                    class="textarea"
                    formControlName="contentReviewHtml"
                    [ngxSummernote]="config"
                    [ngxSummernoteDisabled]="editorDisabled"
                    (mediaDelete)="onDelete($event)"
                    (summernoteInit)="summernoteInit($event)"
                  ></div>
                  <div
                    *ngIf="!validEntity && f.contentReviewHtml.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.contentReviewHtml.errors.required">
                      Por favor, informe a correção do teste.
                    </div>
                  </div>
                </div>
                <span
                  class="text-primary mt-2"
                  *ngIf="contentReviewUpdated"
                ></span>
                <div
                  *ngIf="contentReviewUpdated"
                  class="spinner-border spinner-border-sm text-primary mr-2"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span *ngIf="contentReviewUpdated"
                  >Salvando sua correção automaticamente...</span
                >
                <div class="progress mt-2" *ngIf="contentReviewUpdated">
                  <div
                    class="progress-bar progress-bar-striped bg-primary-500 progress-bar-animated"
                    role="progressbar"
                    [style.width]="progress + '%'"
                    aria-valuenow="progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div *ngIf="notification" class="panel-tag mt-2">
                  {{ notification }}
                </div>
                <div class="form-group" *ngIf="previewMode">
                  <label class="form-label" for="contentReviewHtml"
                    >Teste do aluno</label
                  >
                  <div class="card card-body" *ngIf="f">
                    <div [innerHTML]="contentReviewHtml | safeHtml"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="form-group" *ngIf="!previewMode">
                  <label class="form-label" for="additionalInformation"
                    >Informações complementares</label
                  >
                  <textarea
                    class="form-control"
                    id="example-textarea"
                    rows="5"
                    id="additionalInformation"
                    formControlName="additionalInformation"
                    class="form-control"
                    placeholder="Informações complementares"
                    maxlength="300"
                    [ngClass]="{
                      'is-invalid':
                        !validEntity && f.additionalInformation.errors
                    }"
                    required
                  ></textarea>
                </div>
                <div class="form-group" *ngIf="previewMode">
                  <label class="form-label" for="additionalInformation"
                    >Informações complementares</label
                  >
                  <label class="form-control font-weight-bold disabled">{{
                    f.additionalInformation.value
                  }}</label>
                </div>
              </div>
            </div>
            <div
              class="alert alert-warning alert-dismissible text-left fade show mt-3"
              role="alert"
              *ngIf="!validEntity"
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
              {{ errorMessage }}
            </div>
            <hr />
            <div class="row no-gutters">
              <div class="col-md-12 ml-auto text-right">
                <div class="btn-group btn-panel">
                  <button
                    id="js-login-btn"
                    class="btn btn-primary"
                    [disabled]="submitted"
                    *ngIf="!previewMode"
                  >
                    <span
                      *ngIf="submitted"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>
                    Salvar
                  </button>
                  <button
                    id="js-cancel-btn"
                    class="btn btn-default"
                    (click)="onCancel()"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
