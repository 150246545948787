<div class="col-md-12">
    <ul class="notification">
        <li *ngFor="let notice of notificationlist | paginate : {
                itemsPerPage: pageSize,
                currentPage: page,
                totalItems: notificationCount
            }; let i = index" [ngClass]="{ 'unread': notice.confirmed }" [class.active]="i == currentIndex">
            <a href="javascript:void(0)" class="d-flex align-items-center" (click)="viewNotification(mdNotificationView, notice, i)">
                <span class="d-flex flex-column flex-1 ml-1">
                    <span class="name mb-3">{{notice.notification.title}} <span [innerHtml]="notice.designMessageType"></span></span>
                    <span class="msg-a fs-sm" [innerHtml]="notice.description"></span>
                    <!-- <span class="msg-b fs-xs">Hello again and thanks for being part...</span> -->
                    <span class="badge border border-secondary text-secondary mt-2 w-25">{{notice.notificationDatetime | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                    <!-- <span class="fs-nano text-muted mt-1">{{notice.notificationDatetime | date:'dd/MM/yyyy HH:mm:ss'}}</span> -->
                </span>
            </a>
        </li>
    </ul>
</div>
<hr>
<div class="col-md-12">
    <pagination-controls class="ngx-pagination responsive pagination justify-content-center m-0"
    previousLabel="Anterior" nextLabel="Próxima" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
</div>

<ng-template #mdNotificationView let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Notificação</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c('Close click')">
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div id="panel-3" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>
                    <span class="fw-300"><i>Dados da notificação</i></span>
                </h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content p-0">
                    <notification-view notificationId="{{notificationId}}"></notification-view>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="confirmedNotification(sidNotification);">OK</button>
    </div>
</ng-template>
