import {
  AfterViewInit,
  ElementRef,
  Component,
  ViewChild,
  Renderer2,
  HostListener,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { default as swal } from 'sweetalert2';

import { MessageService } from '@app/shared/services';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { CalendarService } from '@app/student/services/calendar.service';
import { InitStudentService } from '@app/student/services/init-student.service';
import { CryptoService } from '@app/shared/services';
import { CourseService } from '@app/admin/services';
import { Student, Course } from '@app/admin/model';
import { environment } from '@environments/environment';

@Component({
  selector: 'jitsi-meeting',
  templateUrl: './jitsi-meeting.component.html',
  styleUrls: ['./jitsi-meeting.component.css'],
})
export class JitsiMeetingComponent implements AfterViewInit {
  @ViewChild('jitsiContainer') jitsiContainer: ElementRef;
  domain: string = environment.meetDomain;
  options: any;
  api: any;

  public participantId: string = '';
  private classRoom: string = '';
  public returnMessage = '';

  private student: Student;
  private course: Course;

  private returnUrl: string = '/student/calendar';
  private endMeetingUrl: string = '/student/home';

  constructor(
    private calendarService: CalendarService,
    private initStudent: InitStudentService,
    private activatedRoute: ActivatedRoute,
    private cryptoService: CryptoService,
    private courseService: CourseService,
    private loading: MessageService,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.activatedRoute.params.subscribe((params) => {
      if (params['classRoom']) {
        this.classRoom = this.cryptoService.decrypt(params['classRoom']);
        if (this.classRoom) {
          this.showSwalLoading();
          this.getUserData();
        } else {
          this.router.navigate([this.returnUrl]);
        }
      } else {
        this.router.navigate([this.returnUrl]);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.classRoom) {
      return;
    } else {
      this.router.navigate([this.returnUrl]);
    }
  }

  private checkDecryptClassRoomIsValid(decrypt: string): boolean {
    return false;
  }

  // Função que será chamada sempre que a janela for redimensionada
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.setComponentDimensions(); // Redefine as dimensões ao redimensionar a janela
  }

  private getUserData() {
    this.initStudent
      .getStudentUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.student = response;
          this.checkHaveMeetingSchedule();
          this.initStudent.setConfigPage(true, false, true);

          this.getCourseByClassRoom(this.classRoom);
        },
        (error: any) => {
          this.returnMessage = 'Erro ao carregar o calendário.';
          this.loading.hideLoading();
        }
      );
  }

  private getCourseByClassRoom(classRoom: string) {
    this.courseService
      .getClassRoom(classRoom)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response?.typeResponse == TypeResponse.Success) {
            this.course = response?.data;
            this.startNewMeeting();
          }
        },
        (error: any) => {
          this.course = null;
          this.returnMessage = 'Erro ao carregar a turma.';
          this.loading.hideLoading();
        }
      );
  }

  startNewMeeting() {
    this.options = {
      roomName: this.course ? this.course?.name : this.classRoom,
      userInfo: {
        displayName: this.student ? this.student?.name : 'Aluno(a)',
        moderator: false, // Define o usuário como moderador se for o caso
      },
      parentNode: this.jitsiContainer.nativeElement,
      configOverwrite: {
        disableDeepLinking: true, // Desabilita o deep linking para que o navegador seja a escolha padrão
      },
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        SHOW_JITSI_WATERMARK: false, // Remove a logo Jitsi
        SHOW_BRAND_WATERMARK: true, // Ativa o uso da sua própria logo
        BRAND_WATERMARK_LINK: 'https://englishcoffee.com.br', // Link para o site da sua empresa
        HIDE_DEEP_LINKING_LOGO: true, // Esconde o logo de deep linking
        DEFAULT_LOGO_URL:
          'https://englishcoffee.com.br/assets/images/logo/logo-english-coffee.png', // URL da logo da sua empresa
        DISPLAY_WELCOME_PAGE_CONTENT: false, // Remove a página de boas-vindas
        TOOLBAR_BUTTONS: [
          'microphone',
          'camera',
          'desktop',
          'fullscreen',
          'hangup',
          'chat',
          'raisehand',
          'tileview',
          // Não incluir 'invite' aqui para remover o botão
        ],
        HIDE_INVITE_MORE_HEADER: true, // Para garantir que não apareça "Convidar mais pessoas"

        DEFAULT_REMOTE_DISPLAY_NAME: this.student
          ? this.student?.name
          : 'Aluno(a)',
      },
    };
    this.api = new (window as any).JitsiMeetExternalAPI(
      this.domain,
      this.options
    );
    this.api.on('readyToClose', () => {
      this.router.navigate([this.endMeetingUrl]);
    });
    this.registerEvents();
  }

  showSwalLoading() {
    swal({
      title: 'Preparando sua sala para a aula!',
      timer: 6000,
      onOpen: () => {
        swal.showLoading();
      },
    }).then((result: any) => {
      this.loading.hideLoading();
      swal.hideLoading();
    });
  }

  // Função que ajusta o componente com base nas dimensões da janela
  setComponentDimensions(): void {
    const width = window.innerWidth; // Largura atual da janela
    const height = window.innerHeight; // Altura atual da janela

    // Seletor do componente que você deseja redimensionar
    const component = document.querySelector('#jitsiConferenceFrame0');

    if (component) {
      // Definir dinamicamente o estilo de largura e altura com base nas dimensões da janela
      this.renderer.setStyle(component, 'width', `${width * 0.7}px`); // Exemplo: 80% da largura
      this.renderer.setStyle(component, 'height', `${height * 0.7}px`); // Exemplo: 80% da altura
    }
  }

  private checkHaveMeetingSchedule() {
    if (this.student && this.initStudent.CheckHavePermission('EST1AL7MT35')) {
      this.calendarService.nextClassEvent(this.student.id).subscribe(
        (response: any) => {
          let checkClass = false;
          if (response.typeResponse === TypeResponse.Success) {
            let nextClassEvents = response.data;
            if (nextClassEvents?.length > 0) {
              nextClassEvents.forEach((e: any) => {
                if (e.extendedProps.classRoom == this.classRoom) {
                  checkClass = true;
                }
              });
            }
          }

          if (checkClass) {
            return;
          } else {
            this.router.navigate([this.returnUrl]);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate([this.returnUrl]);
        }
      );
    } else {
      this.router.navigate([this.returnUrl]);
    }
  }

  registerEvents(): void {
    this.api.addEventListener('videoConferenceJoined', (event: any) => {
      console.log('Meeting started at:', new Date());
      console.log('User joined:', event.id);
      this.participantId = event.id;
      this.api.executeCommand('toggleFullScreen', true);
      // Definir o tamanho inicial com base no tamanho da tela ao carregar o componente
      this.setComponentDimensions();
      this.loading.hideLoading();
    });

    this.api.addEventListener('videoConferenceLeft', (event: any) => {
      console.log('Meeting ended at:', new Date());
      console.log('User left:', event.id);
    });

    this.api.addEventListener('participantJoined', (participant: any) => {
      // Registre ou processe a entrada do participante
      console.log('Participant joined:', participant.id);

      if (participant.displayName === 'Teacher') {
        this.promoteAndDemoteModerator(this.participantId, participant.id);
      }

      // Definir o tamanho inicial com base no tamanho da tela ao carregar o componente
      this.setComponentDimensions();
    });

    this.api.addEventListener('participantLeft', (participant: any) => {
      // Registre ou processe a saída do participante
      console.log('Participant left:', participant.id);
      this.router.navigate([this.endMeetingUrl]);
    });

    this.api.addEventListener('audioMuteStatusChanged', (event: any) => {
      console.log('Audio mute status changed:', event);
      // Registre mudanças no status de áudio
    });

    this.api.addEventListener('videoMuteStatusChanged', (event: any) => {
      console.log('Video mute status changed:', event);
      // Registre mudanças no status de vídeo
    });

    this.api.addEventListener('cameraError', (event: any) => {
      console.log('Participant camera error:', event);
      // Registre erros relacionados a camera do participante
    });

    // Adicione a propriedade fullscreen ao iframe assim que ele for carregado
    this.api.addEventListener('iframeDisplay', () => {
      this.addFullscreenToIframe();
      // Definir o tamanho inicial com base no tamanho da tela ao carregar o componente
      this.setComponentDimensions();
    });

    // Adicione mais listeners conforme necessário
    //https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe-events/
  }

  promoteAndDemoteModerator(
    currentModeratorId: string,
    newModeratorId: string
  ) {
    // Promover novo participante a moderador
    this.api.executeCommand('grantModerator', newModeratorId);
    console.log(`Participante ${newModeratorId} foi promovido a moderador`);

    // Verifique se o moderador atual ainda está na chamada
    this.api.addEventListener('moderatorRoleChanged', (event) => {
      if (event.id === newModeratorId && event.role === 'moderator') {
        console.log(`O participante ${newModeratorId} agora é moderador`);

        // Tente remover privilégios de moderador do participante anterior sem desconectá-lo
        this.api.executeCommand('muteParticipant', currentModeratorId); // Pode usar comandos alternativos para diminuir o controle do moderador anterior
        console.log(
          `Moderador anterior ${currentModeratorId} teve seu poder limitado`
        );

        // Poderíamos remover permissões adicionais se suportado (sem desconectar)
      }
    });
  }

  // Função para promover um participante a moderador e remover o status de moderador do usuário atual
  promoteAndDemoteModerator2(
    currentModeratorId: string,
    newModeratorId: string
  ) {
    // Promove o novo participante a moderador
    this.api.executeCommand('grantModerator', newModeratorId);
    console.log(`Participante ${newModeratorId} foi promovido a moderador`);

    // // Remove o moderador atual ao desconectá-lo e reconectá-lo como convidado
    // this.api.executeCommand('kickParticipant', currentModeratorId);
    // console.log(`Moderador atual ${currentModeratorId} foi removido`);

    // // Reconectar o moderador atual como convidado
    // setTimeout(() => {
    //   this.api.dispose(); // Desconecta a sessão do moderador atual
    //   //this.startNewMeeting();
    //   // // Reconecta como convidado
    //   this.options.userInfo = {
    //     displayName: this.student ? this.student?.name : 'Aluno(a)',
    //     moderator: false,
    //   };
    //   this.api = new (window as any).JitsiMeetExternalAPI(
    //     this.domain,
    //     this.options
    //   );
    //   console.log(`Moderador atual reconectado como convidado`);
    // }, 3000); // Pequeno atraso para garantir a desconexão antes de reconectar
  }

  addFullscreenToIframe(): void {
    const iframe = document.querySelector('#jitsiConferenceFrame0');
    if (iframe) {
      this.renderer.setAttribute(
        iframe,
        'allow',
        'camera; microphone; fullscreen'
      ); // Adiciona a propriedade fullscreen
      this.enterFullScreen();
    }
  }

  enterFullScreen(): void {
    const iframe = this.jitsiContainer.nativeElement.children[0];
    if (iframe?.requestFullscreen) {
      iframe.requestFullScreen();
    } else if (iframe?.mozRequestFullScreen) {
      // Firefox
      iframe.mozRequestFullScreen();
    } else if (iframe?.webkitRequestFullscreen) {
      // Chrome, Safari e Opera
      iframe.webkitRequestFullscreen();
    } else if (iframe?.msRequestFullscreen) {
      // IE/Edge
      iframe.msRequestFullscreen();
    }
  }

  ngOnDestroy(): void {
    if (this.api) {
      this.api.dispose();
    }
  }
}
