<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/teacher/home']">Home</a>
  </li>
  <li class="breadcrumb-item active">Agenda</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div class="d-md-none">
  <div class="accordion accordion-hover mb-2" id="js_demo_accordion-5">
    <div class="card">
      <div class="card-header">
        <a
          href="javascript:void(0);"
          class="card-title collapsed"
          data-toggle="collapse"
          data-target="#js_demo_accordion-5a"
          aria-expanded="false"
        >
          <i class="fal fa-code-merge width-2 fs-xl"></i> Legenda & Cores
          <span class="ml-auto">
            <span class="collapsed-reveal">
              <i class="fal fa-chevron-up fs-xl"></i>
            </span>
            <span class="collapsed-hidden">
              <i class="fal fa-chevron-down fs-xl"></i>
            </span>
          </span>
        </a>
      </div>
      <div
        id="js_demo_accordion-5a"
        class="collapse"
        data-parent="#js_demo_accordion-5"
      >
        <div class="card-body">
          <span
            class="fw-500 badge badge-pill fs-xl d-block color-primary-500 mb-2"
            [ngClass]="item.css"
            *ngFor="let item of statusClassList"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none d-md-block">
  <div class="subheader">
    <div
      class="subheader-block d-lg-flex align-items-center"
      *ngFor="let item of statusClassList"
    >
      <div class="d-inline-flex flex-column justify-content-center mr-3">
        <span
          class="fw-500 badge badge-pill fs-xl d-block color-primary-500"
          [ngClass]="item.css"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</div>

<div id="panel-2" class="panel">
  <div class="panel-hdr bg-primary-600 bg-primary-gradient">
    <h2>
      Agenda do <span class="fw-300"><i>Professor</i></span>
    </h2>
    <div class="panel-toolbar">
      <a
        href="javascript:void(0);"
        class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Collapse"
      >
        <i class="fal fa-minus text-white fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Fullscreen"
      >
        <i class="fal fa-expand text-primary fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Close"
      >
        <i class="fal fa-times text-white fw-900"></i>
      </a>
    </div>
  </div>
  <div class="panel-container show">
    <div class="panel-content p-0">
      <div class="form-row">
        <div class="col-12">
          <form>
            <div class="panel-content">
              <ng-container *ngIf="calendarOptions2$ | async as options">
                <full-calendar
                  [options]="options"
                  #calendar
                  id="fullCalendarTeacher"
                >
                  <ng-template #eventContent let-arg>
                    <b>{{ arg.timeText }}</b>
                    <i>{{ arg.event.title }}</i>
                  </ng-template>
                </full-calendar>
              </ng-container>
            </div>
          </form>
        </div>
        <div class="col-12">
          <ng-template #mdCalendarDetails let-c="close" let-d="dismiss">
            <div class="modal-header bg-primary">
              <h5 class="modal-title text-white">Informações da aula</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="dismissModal()"
              >
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div
                    class="card border shadow-0 shadow-sm-hover"
                    data-filter-tags="oliver kopyov"
                  >
                    <div
                      class="card-body border-faded border-top-0 border-left-0 border-right-0 rounded-top"
                    >
                      <div class="d-flex flex-row align-items-center">
                        <h4 class="text-primary fw-700">Aluno</h4>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <span
                          class="rounded-circle profile-image d-block mr-3"
                          style="
                            background-image: url('assets/img/demo/avatars/avatar-m.png');
                            background-size: cover;
                          "
                        ></span>
                        <div class="info-card-text flex-1">
                          <a
                            href="javascript:void(0);"
                            class="fs-xl text-truncate text-truncate-lg text-info"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {{ eventDetails.student }}
                          </a>
                        </div>
                        <button
                          class="js-expand-btn btn btn-sm btn-default d-none"
                          data-toggle="collapse"
                          data-target="#c_1 > .card-body + .card-body"
                          aria-expanded="false"
                        >
                          <span class="collapsed-hidden">+</span>
                          <span class="collapsed-reveal">-</span>
                        </button>
                      </div>
                    </div>
                    <div
                      class="card-body border-faded border-top-0 border-left-0 border-right-0 rounded-top"
                    >
                      <div class="d-flex flex-row align-items-center">
                        <h4 class="text-primary fw-700">Professor</h4>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <span
                          class="rounded-circle profile-image d-block mr-3"
                          style="
                            background-image: url('assets/img/demo/avatars/avatar-m.png');
                            background-size: cover;
                          "
                        ></span>
                        <div class="info-card-text flex-1">
                          <a
                            href="javascript:void(0);"
                            class="fs-xl text-truncate text-truncate-lg text-info"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {{ eventDetails.teacher }}
                          </a>
                        </div>
                        <button
                          class="js-expand-btn btn btn-sm btn-default d-none"
                          data-toggle="collapse"
                          data-target="#c_1 > .card-body + .card-body"
                          aria-expanded="false"
                        >
                          <span class="collapsed-hidden">+</span>
                          <span class="collapsed-reveal">-</span>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0 collapse show">
                      <div class="p-3">
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h4 fw-400 text-dark"
                        >
                          <i class="fal fa-users text-primary mr-2"></i>
                          <strong>Turma: </strong> {{ eventDetails.course }}
                          <span
                            class="badge badge-pill pull-right"
                            [ngClass]="eventDetails.courseTypeCss"
                            >{{ eventDetails.courseTypeStr }}</span
                          >
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h4 fw-400 text-dark"
                        >
                          <i class="fal fa-file-alt text-primary mr-3"></i>
                          {{ eventDetails.class }}
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h4 fw-400 text-dark"
                        >
                          <i class="fal fa-clock text-primary mr-3"></i>
                          <strong>Início: </strong>
                          {{ eventDetails.start | date : "medium" }}
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h4 fw-400 text-dark"
                        >
                          <i class="fal fa-clock text-primary mr-3"></i>
                          <strong>Término: </strong>
                          {{ eventDetails.end | date : "medium" }}
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h4 fw-400 text-dark"
                        >
                          <i class="fal fa-clock text-primary mr-3"></i>
                          <strong>Duração da Aula: </strong>
                          {{ eventDetails.duration | date : "HH:mm:ss" }}
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h4 fw-400 text-dark"
                        >
                          <div
                            [innerHtml]="eventDetails.designStatusClass"
                          ></div>
                        </a>
                        <hr />
                        <div class="d-flex flex-row">
                          <h4 class="text-primary fw-700">Conteúdo aplicado</h4>
                        </div>
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h5 fw-400 text-secondary"
                        >
                          <i class="fal fa-video text-secondary mr-3"></i>
                          Série:
                          <strong
                            class="fw-700"
                            *ngIf="eventDetails.episode?.contentSerie"
                            >{{
                              eventDetails.episode?.contentSerie?.name
                            }}</strong
                          >
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="mt-1 d-block h5 fw-400 text-secondary"
                        >
                          <i class="fal fa-video text-secondary mr-3"></i>
                          Episódio:
                          <strong class="fw-700" *ngIf="eventDetails.episode"
                            >{{ eventDetails.episode?.contentSerie?.name }} |
                            {{ eventDetails.episode?.order | number : "2." }} |
                            {{ eventDetails.episode?.item }}</strong
                          >
                        </a>
                        <div
                          *ngIf="
                            !haveEpisode(eventDetails) &&
                            checkEditByStatus(eventDetails)
                          "
                          class="text-danger"
                        >
                          <div>{{ episodeSetMessage }}*</div>
                          <br />
                        </div>
                        <div
                          *ngIf="
                            !haveEpisode(eventDetails) &&
                            !checkEditByStatus(eventDetails)
                          "
                          class="text-primary"
                        >
                          O status atual da aula não permite alterações!
                          <br />
                        </div>
                        <div class="panel-toolbar mb-2">
                          <button
                            type="button"
                            class="btn btn-info btn-xs pull-left"
                            *ngIf="checkEditByStatus(eventDetails)"
                            (click)="
                              studentCourseClassWithForm(
                                eventDetails.id,
                                mdStudentCourseClassConfirmedWithParams
                              )
                            "
                          >
                            <i class="fal fa-edit mr-1"></i> Editar conteúdo
                          </button>
                        </div>
                        <div
                          *ngIf="
                            eventDetails.confirmedClassByStudent ||
                            eventDetails.confirmedClassByTeacher
                          "
                        >
                          <span
                            class="badge border border-status-class-partial-confirmed w-100"
                            *ngIf="eventDetails.confirmedClassByStudent"
                          >
                            Aula confirmada pelo aluno
                            <span
                              class="badge bg-status-class-partial-confirmed ml-2"
                              >{{
                                eventDetails.confirmedDateByStudent
                                  | date : "medium"
                              }}</span
                            >
                          </span>
                          <span
                            class="badge border border-warning badge-warning mt-2 pt-2 pb-1 w-100 text-danger fw-700"
                            *ngIf="!eventDetails.confirmedClassByStudent"
                          >
                            ALUNO AINDA NÃO CONFIRMOU A AULA
                          </span>

                          <span
                            class="badge border border-status-class-partial-confirmed mt-2 w-100"
                            *ngIf="eventDetails.confirmedClassByTeacher"
                          >
                            Aula confirmada pelo professor
                            <span
                              class="badge bg-status-class-partial-confirmed ml-2"
                              >{{
                                eventDetails.confirmedDateByTeacher
                                  | date : "medium"
                              }}</span
                            >
                          </span>
                          <span
                            class="badge border border-warning badge-warning mt-2 pt-2 pb-1 w-100 text-danger fw-700"
                            *ngIf="!eventDetails.confirmedClassByTeacher"
                          >
                            PROFESSOR AINDA NÃO CONFIRMOU A AULA
                          </span>
                          <div
                            class="alert border-faded bg-transparent text-secondary fade show mb-2 mt-2"
                            role="alert"
                            *ngIf="
                              eventDetails.confirmedClassByTeacher &&
                              eventDetails.confirmedClassByTeacherStatusClassParams
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="alert-icon">
                                <span class="icon-stack icon-stack-md">
                                  <i
                                    class="base-7 icon-stack-3x color-success-600"
                                  ></i>
                                  <i
                                    class="fal fa-check icon-stack-1x text-white"
                                  ></i>
                                </span>
                              </div>
                              <div class="flex-1">
                                <span class="h5 color-success-600"
                                  >Confirmado com justificativa!!</span
                                >
                                <br />
                                <strong class="fw-700">{{
                                  eventDetails.confirmedClassByTeacherStatusClassParamsStr
                                }}</strong>
                                <br />
                                Info Adicional:
                                {{
                                  eventDetails.confirmedClassByTeacherAddInfo
                                }}
                              </div>
                            </div>
                          </div>
                          <!-- <div class="alert alert-warning mb-2 mt-2" role="alert" *ngIf="eventDetails.confirmedClassByTeacher && eventDetails.confirmedClassByTeacherStatusClassParams">
                                                        Confirmado com justificativa!:
                                                        <br>
                                                        <strong class="fw-700">{{eventDetails.confirmedClassByTeacherStatusClassParamsStr}}</strong>
                                                        <br>
                                                        Justificativa: <small>{{eventDetails.confirmedClassByTeacherAddInfo}}</small>
                                                    </div> -->
                        </div>
                        <!-- <a type="button" *ngIf="checkClassRoomAccess(eventDetails)" class="btn btn-outline-primary btn-pills waves-effect waves-themed mt-3"
                                                    [href]="getClassRoomUrl(eventDetails.classRoom)" target="_blank">
                                                    <span class="fal fa-video mr-1"></span>
                                                    Aula ao vivo
                                                </a>
                                                <a type="button" *ngIf="checkClassRoomAccess(eventDetails)" class="btn btn-outline-info btn-pills waves-effect waves-themed mt-3 ml-3"
                                                    [href]="getClassRoomUrl(eventDetails.classRoom)" target="_blank">
                                                    <span class="fal fa-video mr-1"></span>
                                                    Aula ao vivo [Alternativo]
                                                </a> -->
                        <div
                          class="panel-tag panel-tag mb-2 mt-3"
                          *ngIf="!checkClassRoomAccess(eventDetails)"
                        >
                          <span *ngIf="haveEpisode(eventDetails)">
                            O link para acesso a
                            <span class="fw-700">sala de aula ao vivo</span>
                            será disponibilizado somente no dia da aula.
                          </span>
                          <span *ngIf="!haveEpisode(eventDetails)">
                            Preencha todas as informações da aula antes de
                            iniciar.
                          </span>
                          <br />
                          <span
                            *ngIf="!checkEditByStatus(eventDetails)"
                            class="text-primary"
                          >
                            O status atual da aula não permite alterações!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary waves-effect waves-themed pull-left"
                (click)="dismissModal()"
              >
                <span class="fal fa-arrow-left mr-1"></span> Voltar
              </button>
              <a
                type="button"
                class="btn btn-sm btn-primary waves-effect waves-themed text-white"
                (click)="checkConfirmClass(eventDetails)"
                *ngIf="checkClassRoomAccess(eventDetails)"
              >
                <span class="fal fa-video mr-1"></span>
                Entrar na aula ao vivo
              </a>
              <a
                type="button"
                class="btn btn-sm btn-secondary waves-effect waves-themed text-white"
                *ngIf="checkRescheduleButton(eventDetails)"
                (click)="
                  studentCourseClassReschedule(
                    eventDetails,
                    mdStudentCourseClassReschedule
                  )
                "
              >
                <span class="fal fa-calendar-times mr-1"></span> Remarcar
              </a>
              <a
                type="button"
                class="btn btn-sm btn-info waves-effect waves-themed text-white"
                *ngIf="checkTeacherChangeButton(eventDetails)"
                (click)="
                  studentCourseClassWithForm(
                    eventDetails.id,
                    mdStudentCourseClassTeacherChange
                  )
                "
              >
                <span class="fal fa-user mr-1"></span> Troca de professor
              </a>
              <!-- <br>
                            <a [href]="'https://meet.jit.si/ec-class-room-'+eventDetails.classRoom">https://meet.jit.si/ec-class-room-{{eventDetails.classRoom}}</a> -->
            </div>
          </ng-template>
        </div>
        <div class="col-12">
          <ng-template
            #mdStudentCourseClassTeacherChange
            let-c="close"
            let-d="dismiss"
          >
            <calendar-teacher-changes
              studentCourseClassId="{{ studentCourseClassId }}"
            ></calendar-teacher-changes>
          </ng-template>

          <ng-template
            #mdStudentCourseClassReschedule
            let-c="close"
            let-d="dismiss"
          >
            <div class="modal-header">
              <h5 class="modal-title">Reagendamento da aula</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="dismissModal()"
              >
                <span aria-hidden="true"><i class="fa fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
                <div class="col-12 mb-3">
                  <input
                    type="hidden"
                    id="studentClassesCourseExpectedDate"
                    formControlName="studentClassesCourseExpectedDate"
                  />
                  <label class="form-label" for="studentClassesCourseReschedule"
                    >Nova data da aula <span class="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    id="studentClassesCourseReschedule"
                    formControlName="studentClassesCourseReschedule"
                    class="form-control"
                    placeholder="Nova data da aula"
                    required
                    (change)="setValueDate($event.target)"
                  />
                </div>
                <div class="col-12">
                  <label
                    class="form-label"
                    for="studentClassesCourseExpectedTime"
                    >Horário <span class="text-danger">*</span>
                  </label>
                  <input
                    type="time"
                    id="studentClassesCourseExpectedTime"
                    formControlName="studentClassesCourseExpectedTime"
                    [ngModel]="studentClassesCourseExpectedTime"
                    class="form-control"
                    placeholder="Horário de início da aula"
                    required
                    (change)="setValueTime($event.target)"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary waves-effect waves-themed"
                data-dismiss="modal"
                (click)="dismissModal()"
              >
                <i class="fa fa-times-circle mr-1"></i> Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success waves-effect waves-themed"
                (click)="
                  willChangeClassReschedule(studentClassesCourseReschedule)
                "
              >
                <i class="fa fa-check mr-1"></i> Confirmar
              </button>
            </div>
          </ng-template>

          <ng-template
            #mdStudentCourseOpenClassRoom
            let-c="close"
            let-d="dismiss"
          >
            <div class="modal-header">
              <h5 class="modal-title">Sala de aula ao Vivo</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="dismissModal()"
              >
                <span aria-hidden="true"><i class="fa fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <app-classroom courseData="{{ courseData }}"></app-classroom>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary waves-effect waves-themed"
                data-dismiss="modal"
                (click)="dismissModal()"
              >
                <i class="fa fa-times-circle mr-1"></i> Sair
              </button>
            </div>
          </ng-template>

          <ng-template
            #mdStudentCourseClassConfirmedWithParams
            let-c="close"
            let-d="dismiss"
          >
            <div class="modal-header">
              <h5 class="modal-title">Confirmação de aula com justificativa</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="dismissModal()"
              >
                <span aria-hidden="true"><i class="fa fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row justify-content-center">
                <div class="col-md-12 mb-3">
                  <label class="form-label"
                    >Série <span class="text-danger">*</span>
                  </label>
                  <br />
                  <select
                    class="form-control"
                    id="serieId"
                    placeholder="Série"
                    (change)="getEpisodesBySerieId($event?.target?.value)"
                    [(ngModel)]="serieId"
                  >
                    <option
                      *ngFor="let item of seriesDataList"
                      [value]="item.id"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label"
                    >Episódio <span class="text-danger">*</span>
                  </label>
                  <br />
                  <select
                    class="form-control"
                    id="episodeId"
                    placeholder="Episódio"
                    [(ngModel)]="episodeId"
                    (change)="setEpisodeId($event?.target?.value)"
                  >
                    <option
                      *ngFor="let item of episodesDataList"
                      [value]="item.id"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row justify-content-center" *ngIf="confirmWithParams">
                <div class="col-md-12 mb-3">
                  <label class="form-label" for="newStatusClassParams"
                    >Justificativa para confirmação
                    <span class="text-danger">*</span>
                  </label>
                  <br />
                  <select
                    class="form-control"
                    id="newStatusClassParams"
                    placeholder="Justificativa"
                    (change)="setNewStatusClassParams($event?.target?.value)"
                    [(ngModel)]="newStatusClassParamsId"
                  >
                    <option
                      *ngFor="let item of statusClassParamsDataList"
                      [value]="item.id"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                  <span
                    class="badge badge-pill col-8 mt-2"
                    [ngClass]="
                      newStatusClassParamsId >= 0 ? newStatusClassParamsCss : ''
                    "
                    >{{ newStatusClassParamsStr }}</span
                  >
                  <div
                    *ngIf="
                      !validStatusClassParams && errorStatusClassParamsMessage
                    "
                    class="invalid-feedback"
                  >
                    <div>{{ errorStatusClassParamsMessage }}.</div>
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label class="form-label" for="statusClassParamsAddInfo"
                    >Informação adicional</label
                  >
                  <textarea
                    rows="3"
                    id="statusClassParamsAddInfo"
                    [(ngModel)]="statusClassParamsAddInfo"
                    placeholder="Informações adicionais"
                    class="form-control rounded-top border-bottom-left-radius-0 border-bottom-right-radius-0 border"
                  ></textarea>
                  <div
                    *ngIf="needAddInfo && !validStatusClassParams"
                    class="invalid-feedback"
                  >
                    <div>
                      Informação adicional é obrigatória para esse status.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm btn-secondary waves-effect waves-themed"
                data-dismiss="modal"
                (click)="dismissModal()"
              >
                <i class="fa fa-times-circle mr-1"></i> Cancelar
              </button>
              <button
                type="button"
                class="btn btn-sm btn-success waves-effect waves-themed"
                (click)="confirmedClassWithParams(studentCourseClassId)"
              >
                <i class="fa fa-check mr-1"></i> Confirmar
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
