import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Teacher,
  WritingTestAppliedReview,
  WritingTestApply,
} from '@app/admin/model';
import { WritingTestApplyService } from '@app/admin/services';
import { MessageService, UtilService } from '@app/shared/services';
import { InitTeacherService } from '@app/teacher/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  AvgScore,
  AvgScoreLabel,
  TypeResponse,
  WritingTestReview,
} from '@app/shared/enum';
import { default as swal } from 'sweetalert2';
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var $: { summernote: { ui: any } };

@Component({
  selector: 'writing-test-applied-review',
  templateUrl: './writing-test-applied-review.component.html',
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    DatePipe,
    SafePipe,
  ],
  styleUrls: ['./writing-test-applied-review.component.css'],
})
export class WritingTestAppliedReviewComponent implements OnInit {
  @Input('writingTestApplyId') writingTestApplyId: any = 0;
  @Input('previewMode') previewMode: any = false;
  private writingTestAppliedReview: WritingTestAppliedReview;
  private writingTestApply: WritingTestApply;
  private teacher: Teacher;

  public contentReviewHtml: string =
    '<div class="alert alert-primary" role="alert">O teste ainda não foi preenchido pelo aluno!</div>';

  text: string = '';
  progress: number = 0;
  notification: string = '';
  autosaveInterval = 10000; // 10 segundos
  private subscription: Subscription | undefined;

  returnUrl = '/teacher/writingtestlist';
  public errorMessage: string = '';

  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']],
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear',
          ],
        ],
      ],
    },
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['fullscreen', 'codeview', 'help']],
    ],
    fontSizes: [
      '8',
      '9',
      '10',
      '11',
      '12',
      '14',
      '18',
      '24',
      '36',
      '44',
      '56',
      '64',
      '76',
      '84',
      '96',
    ],
    fontNames: [
      'Arial',
      'Times New Roman',
      'Inter',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
      'MangCau',
      'BayBuomHep',
      'BaiSau',
      'BaiHoc',
      'CoDien',
      'BucThu',
      'KeChuyen',
      'MayChu',
      'ThoiDai',
      'ThuPhap-Ivy',
      'ThuPhap-ThienAn',
    ],
    buttons: {
      testBtn: customButton,
    },
    codeviewFilter: true,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
  };

  public validEntity: boolean;

  public writingTestAppliedReviewForm: any;
  public editorDisabled = false;
  public submitted = false;

  public avgScoreList: any = [];
  public avgScoreValue: any = 0;

  public contentReviewUpdated: boolean;

  constructor(
    private writingTestApplyService: WritingTestApplyService,
    private initTeacher: InitTeacherService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private loading: MessageService,
    public datepipe: DatePipe,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.previewMode = this.previewMode?.toString() == 'true';
    this.getUserData();
    this.loading.hideLoading();
  }

  private getUserData() {
    this.initTeacher
      .getTeacherUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.teacher = response;
          this.getWritingTestApply(this.writingTestApplyId);
          this.loading.hideLoading();
        },
        (error: any) => {
          this.errorMessage = 'Erro ao carregar os testes disponíveis.';
          this.loading.hideLoading();
        }
      );
  }

  private getWritingTestApply(id: any) {
    this.writingTestApplyService
      .getById(id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.writingTestApply = response.data;
          this.onInitForm(this.writingTestApply);
          this.loading.hideLoading();
        },
        (error: any) => {
          this.errorMessage = 'Erro ao carregar os testes disponíveis.';
          this.loading.hideLoading();
        }
      );
  }

  onInitForm(data: any) {
    this.validEntity = true;
    this.avgScoreList = this.utilService.ToArray(AvgScore, AvgScoreLabel);
    this.avgScoreValue = 0;
    let textReview =
      data.writingTestReview == WritingTestReview.Done
        ? data.contentReviewHtml
        : data.writeContentStudentHtml;

    this.contentReviewHtml =
      textReview === '' ? this.contentReviewHtml : textReview;
    let finishDate = this.datepipe.transform(Date.now(), 'yyyy-MM-dd');
    this.writingTestAppliedReviewForm = this.formBuilder.group({
      writingTestApplyId: new FormControl(data.id, Validators.required),
      contentReviewHtml: new FormControl(
        this.contentReviewHtml,
        Validators.required
      ),
      additionalInformation: new FormControl(
        data.additionalInformation,
        Validators.required
      ),
      scoreResult: new FormControl(data.scoreResult, Validators.required),
      avgScore: new FormControl(data.avgScore, Validators.required),
      finishDate: new FormControl(finishDate, Validators.required),
      writingTestStatus: new FormControl(
        data.writingTestStatus,
        Validators.required
      ),
    });

    this.f?.contentReviewHtml?.valueChanges?.subscribe((change: any) => {
      this.startAutosave();
    });
  }

  public startAutosave(): void {
    if (this.contentReviewUpdated == true) return;

    const saveInterval = this.autosaveInterval / 100;
    this.subscription = interval(saveInterval).subscribe((n) => {
      this.progress = (n % 100) + 1;
      this.contentReviewUpdated = true;
      if (this.progress === 100) {
        this.saveText();
        this.contentReviewUpdated = false;
        this.stopAutosave();
      }
    });
  }

  public stopAutosave(): void {
    this.contentReviewUpdated = false;
    this.progress = 0;
    this.subscription?.unsubscribe();
  }

  saveText(): void {
    if (this.f?.contentReviewHtml?.value) {
      // Chamada para a API para salvar o texto
      this.writingTestApplyService
        .saveTextReview(this.writingTestApply.id, {
          text: this.f?.contentReviewHtml?.value,
        })
        .subscribe(
          (response) => {
            this.notification = 'Texto salvo com sucesso!';
            setTimeout(() => (this.notification = ''), 3000);
          },
          (error) => {
            this.notification = 'Erro ao salvar o texto.';
            setTimeout(() => (this.notification = ''), 3000);
          }
        );
    } else {
      this.notification = 'Erro ao salvar o texto.';
      setTimeout(() => (this.notification = ''), 3000);
    }
  }

  setFormByEntity(data: any) {
    this.validEntity = true;
    this.f.writingTestApplyId.value = data.id;
    this.f.contentReviewHtml.value =
      data.writingTestReview == WritingTestReview.Done
        ? data.contentReviewHtml
        : data.writeContentStudentHtml;
    this.f.scoreResult.value = data.scoreResult;
    this.f.avgScore.value = data.avgScore;
    this.f.writingTestStatus.value = data.writingTestStatus;
    this.f.additionalInformation.value = data.additionalInformation;
    this.f.finishDate.value = data.finishDate
      ? new Date(formatDate(data.finishDate, 'yyyy-MM-dd', 'en-US'))
      : this.f.finishDate.value;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.writingTestAppliedReviewForm?.controls ?? {};
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validEntity = true;

    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.writingTestAppliedReviewForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validEntity = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.writingTestAppliedReview = new WritingTestAppliedReview(
      this.writingTestAppliedReviewForm.value
    );

    this.writingTestApplyService
      .writingTestAppliedReview(
        this.writingTestApplyId,
        this.writingTestAppliedReview
      )
      .pipe(first())
      .subscribe(
        (dataReturn: any) => {
          if (dataReturn.typeResponse == TypeResponse.Success) {
            this.dismissModal();
            swal({
              type: 'success',
              title: 'Avaliação do teste de escrita realizado com sucesso!',
              text: 'O aluno será notificado na imediatamente.',
              showConfirmButton: true,
            }).then((result) => {
              this.validEntity = true;
              this.router.navigate([this.returnUrl]);
            });
          } else {
            this.dismissModal();
            this.validEntity = false;
            this.errorMessage = dataReturn.message;
          }
          this.submitted = false;
          this.loading.hideLoading();
        },
        (error: any) => {
          this.validEntity = false;
          this.submitted = false;
          this.loading.hideLoading();
          this.errorMessage = !error.ok ? 'Erro ao salvar a avaliação.' : error;
        }
      );
  }

  onCancel(): void {
    this.dismissModal();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  public onBlur() {
    console.log('Blur');
  }

  public onDelete(file: any) {
    console.log('Delete file', file.url);
  }

  public summernoteInit(event: any) {
    console.log(event);
  }
}

function customButton(context: any) {
  const ui = $.summernote.ui;
  const button = ui.button({
    contents: '<i class="note-icon-magic"></i> Hello',
    tooltip: 'Custom button',
    container: '.note-editor',
    className: 'note-btn',
    click: function () {
      context.invoke('editor.insertText', 'Hello from test btn!!!');
    },
  });
  return button.render();
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }
}
