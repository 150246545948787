<div class="modal-header">
  <h3 class="modal-title">
    Realização do <span class="fw-700"><i>teste de escrita</i></span>
  </h3>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="dismissModal()"
  >
    <span aria-hidden="true"><i class="fal fa-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <form
    [formGroup]="writingTestApplyStudentForm"
    novalidate
    class="needs-validation"
    (ngSubmit)="onSubmit()"
  >
    <div id="panel-1" class="panel">
      <div class="panel-hdr">
        <h4 class="text-primary fw-700">Revisão do professor</h4>
        <!-- <h2>
                    Dados do <span class="fw-300"><i>Teste</i></span>
                </h2> -->
      </div>
      <div class="panel-container show">
        <div class="panel-content">
          <div class="form-row mb-3" *ngIf="previewMode">
            <div class="col-3">
              <p class="card-text mt-2 mb-1">Status da revisão:</p>
              <div
                [innerHTML]="writingTestApply?.designWritingTestStatus"
              ></div>
            </div>
            <div class="col-3 ml-3">
              <p class="card-text mb-1 mt-2">Data de revisão:</p>
              <span class="fw-700" *ngIf="writingTestApply?.reviewDate">{{
                writingTestApply?.reviewDate | date : "dd/MM/yyyy"
              }}</span>
              <span
                class="badge badge-pill badge-secondary w-100"
                *ngIf="!writingTestApply?.reviewDate"
                >Não revisado</span
              >
            </div>
          </div>
          <div class="form-row">
            <input
              type="hidden"
              id="writingTestApplyId"
              formControlName="writingTestApplyId"
            />
            <input
              type="hidden"
              id="writingTestStatus"
              formControlName="writingTestStatus"
            />
            <input type="hidden" id="finishDate" formControlName="finishDate" />
          </div>
        </div>
      </div>
    </div>
    <div id="panel-2" class="panel" *ngIf="preview">
      <div class="panel-hdr">
        <h4 class="text-primary fw-700">Texto do Aluno</h4>
      </div>
      <div class="panel-container show">
        <div class="panel-content">
          <div class="form-row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label fw-700" for="writingTestContent"
                  >Visualização do texto</label
                >
                <div [innerHTML]="writeContentStudentHtml"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="panel-2" class="panel">
      <div class="panel-hdr">
        <h4 class="text-primary fw-700">Texto/Resultado do teste</h4>
        <!-- <h2>
                    Texto/Resultado do <span class="fw-300"><i>Teste</i></span>
                </h2> -->
      </div>
      <div class="panel-container show">
        <div class="panel-content">
          <div class="form-row">
            <div class="col-12 mb-3">
              <div class="form-group">
                <h4 class="form-label" for="writeContentStudentHtml">
                  Escreva o texto abaixo <span class="text-danger">*</span>
                </h4>
                <div
                  class="textarea"
                  formControlName="writeContentStudentHtml"
                  [(summernoteModel)]="htmlSafeContent"
                  [ngxSummernote]="config"
                  [ngxSummernoteDisabled]="editorDisabled"
                  (mediaDelete)="onDelete($event)"
                  (summernoteInit)="summernoteInit($event)"
                ></div>
                <div
                  *ngIf="!validEntity && f.writeContentStudentHtml.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.writeContentStudentHtml.errors.required">
                    Por favor, informe a texto do teste.
                  </div>
                </div>
              </div>
              <div
                class="spinner-border spinner-border-sm text-primary mr-2"
                role="status"
                *ngIf="contentReviewUpdated"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <span class="text-primary mt-2" *ngIf="contentReviewUpdated"
                >Salvando sua correção automaticamente...</span
              >
              <div class="progress mt-2">
                <div
                  class="progress-bar progress-bar-striped bg-primary-500 progress-bar-animated"
                  role="progressbar"
                  [style.width]="progress + '%'"
                  aria-valuenow="progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div
                *ngIf="notification"
                class="alert mt-2"
                [ngClass]="notification_class"
              >
                {{ notification }}
              </div>
            </div>
            <div class="col-12" *ngIf="preview">
              <div class="form-group">
                <label class="form-label fw-700" for="writingTestContent"
                  >Visualização da resolução</label
                >
                <div [innerHTML]="writingTestContent"></div>
              </div>
              <hr />
              <div class="form-group">
                <label class="form-label fw-700" for="additionalInformation"
                  >Informações complementares</label
                >
                <div [innerHTML]="f?.additionalInformation?.value"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          class="alert alert-warning alert-dismissible text-left fade show mt-3"
          role="alert"
          *ngIf="!validEntity"
        >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
          {{ errorMessage }}
        </div>
        <hr />
        <div class="row no-gutters">
          <div class="col-md-12 ml-auto text-right">
            <div class="btn-group btn-panel">
              <button
                id="js-login-btn"
                class="btn btn-primary"
                [disabled]="submitted || preview"
                (click)="onSubmit()"
                *ngIf="!preview"
              >
                <span
                  *ngIf="submitted"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Salvar
              </button>
              <button
                id="js-cancel-btn"
                class="btn btn-default"
                (click)="dismissModal()"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!--
<div class="card-body">
    <div class="row">
        <div class="col-12">
            <h4>Dados da Turma</h4>
        </div>
        <div class="col-6">
            <p class="card-text mb-1 mt-2">Turma:</p>
            <span class="fw-700">{{writingTestApply.course.name}}</span>
        </div>
        <div class="col-6">
            <p class="card-text mb-1 mt-2">Pacote:</p>
            <span class="fw-700">{{writingTestApply.course.product.name}}</span>
        </div>
        <div class="col-6">
            <p class="card-text mb-1 mt-2">Aluno:</p>
            <span class="fw-700">{{writingTestApply.student.name}}</span>
        </div>
        <div class="col-6">
            <p class="card-text mb-1 mt-2">Professor:</p>
            <span class="fw-700">{{writingTestApply.teacherApplied.name}}</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <h4>Dados da Aula</h4>
        </div>
        <div class="col-4">
            <p class="card-text mb-1 mt-2">Aula:</p>
            <span class="fw-700">{{writingTestApply.studentClassesCourse.numberClass}}</span>
        </div>
        <div class="col-4">
            <p class="card-text mb-1 mt-2">Data prevista:</p>
            <span class="fw-700" *ngIf="writingTestApply.studentClassesCourse.expectedDate">{{writingTestApply.studentClassesCourse.expectedDate | date:'dd/MM/yyyy'}}</span>
            <span class='badge badge-pill badge-secondary w-100' *ngIf="!writingTestApply.studentClassesCourse.expectedDate">Não realizada</span>
        </div>
        <div class="col-4">
            <p class="card-text mb-1 mt-2">Aula realizada:</p>
            <span class="fw-700" *ngIf="writingTestApply.studentClassesCourse.confirmedDate">{{writingTestApply.studentClassesCourse.confirmedDate | date:'dd/MM/yyyy'}}</span>
            <span class='badge badge-pill badge-secondary w-100' *ngIf="!writingTestApply.studentClassesCourse.confirmedDate">Não realizada</span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <h4>Dados do Teste</h4>
        </div>
        <div class="col-12">
            <p class="card-text mb-1 mt-2">Ordem/Teste:</p>
            <span class="fw-700">{{writingTestApply.writingTest.order + " / " + writingTestApply.writingTest.name}}</span>
        </div>
        <div class="col-4">
            <p class="card-text mb-1 mt-2">Data de disponibilização:</p>
            <span class="fw-700" *ngIf="writingTestApply.availabilityDate">{{writingTestApply.availabilityDate | date:'dd/MM/yyyy'}}</span>
            <span class='badge badge-pill badge-secondary w-100' *ngIf="!writingTestApply.availabilityDate">Não disponibilizado</span>
        </div>
        <div class="col-4">
            <p class="card-text mb-1 mt-2">Data de realização:</p>
            <span class="fw-700" *ngIf="writingTestApply.finishDate">{{writingTestApply.finishDate | date:'dd/MM/yyyy'}}</span>
            <span class='badge badge-pill badge-secondary w-100' *ngIf="!writingTestApply.finishDate">Não realizado</span>
        </div>
        <div class="col-4">
            <p class="card-text mb-1 mt-2">
                Status do teste:
            </p>
            <div [innerHTML]="writingTestApply.designWritingTestStatus"></div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <h4 class="text-primary fw-700">Revisão do professor</h4>
        </div>
        <div class="col-3">
            <p class="card-text mt-2 mb-1">Status da revisão:</p>
            <div [innerHTML]="writingTestApply.designWritingTestStatus"></div>
        </div>
        <div class="col-3">
            <p class="card-text mb-1 mt-2">Data de revisão:</p>
            <span class="fw-700" *ngIf="writingTestApply.reviewDate">{{writingTestApply.reviewDate | date:'dd/MM/yyyy'}}</span>
            <span class='badge badge-pill badge-secondary w-100' *ngIf="!writingTestApply.reviewDate">Não revisado</span>
        </div>
        <div class="col-3">
            <p class="card-text mb-1 mt-2">Resultado da avaliação:</p>
            <div [innerHTML]="writingTestApply.designAvgScore"></div>
        </div>
        <div class="col-3">
            <p class="card-text mb-1 mt-2">Score:</p>
            <span class="fw-700">{{writingTestApply.scoreResult}}</span>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-12">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-info waves-effect waves-themed" data-dismiss="modal" (click)="writingTestAppliedReview(wt, mdWritingTestAppliedReview)" [disabled]="!writingTestApply.writingTestReviewShow" *ngIf="!writingTestApplyPreviewMode">
                    <i class="fa fa-file-check mr-2"></i> Avaliar
                </button>
                <button type="button" class="btn btn-info waves-effect waves-themed" data-dismiss="modal" (click)="writingTestAppliedReview(wt, mdWritingTestAppliedReview)" [disabled]="!writingTestApply.writingTestReviewShow" *ngIf="writingTestApplyPreviewMode">
                    <i class="fa fa-file-search mr-2"></i> Visualizar revisão
                </button>
            </div>
        </div>
    </div>
</div> -->
