<div class="row justify-content-start m-3">
    <div class="col-md-8 text-left">
        <label class="form-label text-muted">Data/hora da notificação: <strong class="fw-700">{{notice.notificationDatetime | date:'dd/MM/yyyy HH:mm:ss'}}</strong></label>
    </div>
    <div class="col-md-4 pull-right">
        <span [innerHtml]="notice.designMessageType"></span>
    </div>
</div>

<div class="row m-3">
    <div class="col-12 mb-3">
        <div class="form-group">
            <label class="form-label text-muted">Título</label>
            <input type="text" class="form-control" disabled="" [value]="notice.notification.title">
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <label class="form-label text-muted">Mensagem</label>
            <div class="alert alert-secondary" role="alert" [innerHtml]="notice.description"></div>
            <!-- <textarea rows="3" id="comments" disabled="" placeholder="Escreva seus comentários" [innerHtml]="notice.description"
                class="form-control rounded-top border-bottom-left-radius-0 border-bottom-right-radius-0 border"></textarea> -->
        </div>
    </div>
</div>
